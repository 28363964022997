import dialogContact from "./modules/dialogs/contact";
import dialogVideo from "./modules/dialogs/video";
import lightbox from "./modules/lightbox";
import sliderBanner from "./modules/sliders/banner";
import sliderTestimonial from "./modules/sliders/testimonial";
import utils from "./modules/utils";
import video from "./modules/video";

// Utils
// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
    document.querySelectorAll(
        ".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-1 .ast-builder-html-element,.site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
    )
);

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
// astra.init();

// Dialogs
dialogContact.init();
dialogVideo.init();

// Lightbox
lightbox.init();

// Pagination
// pagination.init();

// Sliders
sliderBanner.init();
// sliderGrid.init();
// sliderLogo.init();
sliderTestimonial.init();

// Tests
// test.log();
// coreWebVitals.init();

// Videos
video.init();
