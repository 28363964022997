import Player from "@vimeo/player";

function init() {
	// console.log("Video scripts");

	// Banner default background video
	const bannerDefaultBgVideo = document.querySelector(
		".banner-default .background-video iframe"
	);

	if (bannerDefaultBgVideo) {
		const bannerDefaultBgVideoAspectRatio = document.querySelector(
			".banner-default .background-video"
		).dataset.aspectRatio;

		bgVideoFull(
			bannerDefaultBgVideo,
			eval(bannerDefaultBgVideoAspectRatio)
		);
		bannerBgVideoFadeIn(bannerDefaultBgVideo);
	}

	// Banner slider background video, first slide only
	const bannerSliderBgVideo = document.querySelector(
		".banner-slider .slide-1 .background-video iframe"
	);

	if (bannerSliderBgVideo) {
		const bannerSliderBgVideoAspectRatio = document.querySelector(
			".banner-slider .slide-1 .background-video"
		).dataset.aspectRatio;

		bgVideoFull(bannerSliderBgVideo, eval(bannerSliderBgVideoAspectRatio));
		bannerBgVideoFadeIn(bannerSliderBgVideo);
	}
}

// Background video fullwidth/height
function bgVideoFull(frame, frameRatio) {
	const viewport = frame.parentElement;

	function resizeVideo(frame) {
		const width = viewport.offsetWidth;
		const height = viewport.offsetHeight;
		const ratio = width / height;
		let targetWidth = width;
		let targetHeight = height;

		if (ratio > frameRatio) {
			// Viewport is wider than video
			// Correct the height
			targetHeight = width / frameRatio;
		} else {
			// Viewport is taller than video
			// Correct the width
			targetWidth = height * frameRatio;
		}

		// Set frame width and height
		frame.setAttribute(
			"style",
			"width: " + targetWidth + "px; height: " + targetHeight + "px"
		);
	}
	window.addEventListener("load", function () {
		resizeVideo(frame);
	});
	window.addEventListener("resize", function () {
		resizeVideo(frame);
	});
}

function bannerBgVideoFadeIn(iframe) {
	if (iframe) {
		const player = new Player(iframe);
		const wrapper = iframe.closest(".video-wrapper");

		// On play, fade in
		player.on("play", function () {
			wrapper.classList.add("fade-in-video");
		});
	}
}

export default { init };
